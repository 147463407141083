import { defineComponent as _defineComponent } from 'vue'
import { SYSTEM } from "@ems/locales/system";
import FileDisplay from "@/components/Common/FileHelper/FileDisplay.vue";
import { onMounted, ref } from "vue";
import CompanyAccountManagementModule from "@ems/containers/CompanyAccountManagement/CompanyAccountManagement.module";
import { getTenantId } from "@/utils/storage";
import { DOCTYPE } from "@ems/constants/doc_type";
import { IFileMapObject } from "@/models/Company";


export default /*@__PURE__*/_defineComponent({
  __name: 'RequiredDocuments',
  props: {
  tenantId: {
    type: String,
  },
},
  setup(__props, { expose: __expose }) {
  __expose();

const companyRegisFile = ref<IFileMapObject>();
const letterOfAuthorFile = ref<IFileMapObject>();
const otherRelevantsFile = ref<IFileMapObject[]>([]);

const props = __props;

onMounted(async () => {
  await CompanyAccountManagementModule.getFiles({
    TenantId: props.tenantId ? props.tenantId : getTenantId()!,
  });
  CompanyAccountManagementModule.getDataFilesGetter.map((file: any) => {
    const mappedFile = {
      name: file.Name,
      size: new Blob([file.Content]).size,
      status: "ready",
      raw: new Blob([file.Content], { type: file.Type }),
      uid: file.Id,
      url: file.Content,
    };
    switch (file.DocType) {
      case DOCTYPE[DOCTYPE.CompanyRegistrationDocument]:
        companyRegisFile.value! = { ...mappedFile };
        break;
      case DOCTYPE[DOCTYPE.LetterOfAuthorization]:
        letterOfAuthorFile.value! = { ...mappedFile };
        break;
      case DOCTYPE[DOCTYPE.OtherRelevantDocuments]:
        otherRelevantsFile.value!.push({ ...mappedFile });
        break;
    }
  });
});

const __returned__ = { companyRegisFile, letterOfAuthorFile, otherRelevantsFile, props, get SYSTEM() { return SYSTEM }, FileDisplay, onMounted, ref, get CompanyAccountManagementModule() { return CompanyAccountManagementModule }, get getTenantId() { return getTenantId }, get DOCTYPE() { return DOCTYPE }, get IFileMapObject() { return IFileMapObject } }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})