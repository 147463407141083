import dayjs from "dayjs";

export const forceDownload = (blobUrl: string, filename: string) => {
  const aTag = document.createElement("a");
  aTag.download = filename;
  aTag.href = blobUrl;
  document.body.appendChild(aTag);
  aTag.click();
  aTag.remove();
};

export const downloadResource = (url: string, filename: string) => {
  fetch(url, {
    headers: new Headers({
      Origin: location.origin,
    }),
  })
    .then((response) => response.blob())
    .then((blob) => {
      const blobUrl = window.URL.createObjectURL(blob);
      forceDownload(blobUrl, filename);
    })
    .catch((e) => console.error(e));
};

export const downloadAllFile = (files: any[]) => {
  files.forEach((file: any) => {
    downloadResource(file.Content, file.Name);
  });
};

export const downloadCSVFile = (csvContent: string, filename: string) => {
  const isMac = navigator.platform.match(/(Mac|iPhone|iPod|iPad)/i);
  if (!isMac) {
    const BOM = "\uFEFF";
    csvContent = BOM + csvContent;
  }
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
  const blobUrl = URL.createObjectURL(blob);
  const link = document.createElement("a");
  const currentDate = dayjs().format();
  if (!link.download) {
    link.setAttribute("href", blobUrl);
    link.setAttribute("download", `${currentDate}_${filename}.csv`);
    link.style.display = "none";
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
};
